import history from "../customHistory";
import jwt_decode from "jwt-decode";

export const logout = (url = '/', clear = true) => {
    if(clear){
        localStorage.clear();
    }
    window.location.href = '/'
};

export const deCodeToken = (token) => {
    if(token){
        return jwt_decode(token);
    } else {
        return null;
    }
}

export const setToken = (type, data) => {
    if(data){
        if(type === 'auth'){
            localStorage.setItem('token' , data?.token);
            localStorage.setItem('firstname', data?.firstName);
            localStorage.setItem('lastname', data?.lastName);
            localStorage.setItem('isStripeConnectOnboardingComplete', data?.stripeDetails?.isStripeConnectOnboardingComplete)
        } else {
            localStorage.setItem('userRole', data?.role.code);
            localStorage.setItem('roleId', data?.role._id);
            if(data?.loop){
              localStorage.setItem('loopId', data?.loop._id);
            }
            if(data?.intermediatoryFirm){
              localStorage.setItem('orgId', data?.intermediatoryFirm._id);
              localStorage.setItem('intermediatoryFirmName', data?.intermediatoryFirm.name);
            }
        }
    }
}

export const checkIsLogin = () => {
    const token = localStorage.getItem('token');
    return !!token;
}