import React from 'react';
import { userRoles } from '../../utils';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={"/static/images/identlogo.png"}

      {...props}
    />
  );
};

export default Logo;
