import { userRoles } from "../../utils";
import request from "./request";
import requestToken from "./requestToken"
import requestTokenPDF from "./requestTokenPDF";

export const getMembership = () => {
    return requestToken({
        url: 'users/memberships',
        method: 'GET',
    })
};

export const getContractPdf = (name) => {
    return requestTokenPDF({
        url: `/contracts/orders/buy/file-download-link?fileName=${name}`,
        method: 'GET'
    })
}

export const agreeContract = (data) => {
    return requestToken({
        url: `contracts/orders/buy/agreement`,
        method: 'PUT',
        data
    })
}

export const contactTokenVerify = token => {
    return request({
        url: `/contracts/orders/buy/verify-contract-link?contractToken=${token}`,
        method: 'GET'
    })
}

export const getProfile = id => {
    return requestToken({
        url: `/users/profile${id ? `?userId=${id}`:``}`,
        method: 'GET'
    })
}

export const verifyRegistration = email => {
    return request({
        url: `/auth/verify-registration?email=${email}`,
        method: 'GET'
    })
}

export const getOnboardingProfile = (data, query = '') => {
    return requestToken({
        url: `/payments/stripe/create-account-link${query}`,
        method: 'POST',
        data
    })
}

export const verifyRegistrationWithToken = token => {
    return request({
        url: `auth/verify-registration${token}`,
        method: 'GET'
    })
}

export const userRegistration = data => {
    return request({
        url: `auth/register`,
        method: 'POST',
        data
    })
}

export const getUserProfileDetails = (userId,workersListFlag) => {
    return requestToken({
        url: `users/profile${userId ? `?${workersListFlag ? `pdaDomain` : `userId`}=${userId}` : ``}`,
        method: 'GET'
    })
}

export const editUserProfile = data => {
    return requestToken({
        url: `users/profile`,
        method: 'PUT',
        data
    })
}

export const inviteUser = data => {
    return requestToken({
        url: `auth/invite`,
        method: 'POST',
        data
    })
}

export const verifyPasswordLink = path => {
    return request({
        url: `auth/verify-change-password-link${path}`,
        method: 'GET'
    })
}

export const getNotification = (query) => {
    return requestToken({
        url: `users/notifications?${query}${localStorage.getItem('loopAdminAsCN') === 'true' && localStorage.getItem('userRole') === userRoles.LOOP_ADMIN ? `&loopAdminAsCN=${true}` : ''}`,
        method: 'GET'
    })
}

export const markNotification = (query, data) => {
    return requestToken({
        url: `users/notifications/statuses/read-update?${query}`,
        method: 'POST',
        data
    })
}

export const changePassword = data => {
    return request({
        url: `auth/change-password`,
        method: 'PUT',
        data
    })
}

export const forgotPasswordConfirm = data => {
    return request({
        url: `auth/forgot-password`,
        method: 'POST',
        data
    })
}
export const fetchLoopList = () => {
    return requestToken({
        url: `loops`,
        method: 'GET'
    })
}

export const verifyRegistrationLocation = location => {
    return request({
        url: `auth/verify-registration${location}`,
        method: 'GET'
    })
}

export const generateInviteLink = (data) => {
    return requestToken({
        url: `auth/referral`,
        method: 'POST',
        data:data
    })
}

export const assignCarNav = (data) => {
    return requestToken({
        url: `orders/buy/reassign`,
        method: 'POST',
        data:data
    })
}

export const assignCarNavPO = (data) => {
    return requestToken({
        url: `orders/sell/reassign`,
        method: 'POST',
        data:data
    })
}

export const getDashboardData = (loopId,orgId) => {
    return requestToken({
        url: `users/dashboard?loopId=${loopId}&orgId=${orgId}${localStorage.getItem('loopAdminAsCN') === 'true' && localStorage.getItem('userRole') === userRoles.LOOP_ADMIN ? `&loopAdminAsCN=${true}` : ''}`,
        method: 'GET'
    })
}


export const SubmitOrder = (data) => {
    return requestToken({
        url: `orders/buy?${localStorage.getItem("userRole") ? `loopId=${localStorage.getItem('loopId')}&orgId=${localStorage.getItem('orgId')}`:`loopCode=HELICOPTER_PILOT&orgCode=IDENT`}`,
        method: 'POST',
        data
    })
};
export const SubmitWorkerOrder = (data) => {
    return requestToken({
        url: `orders/buy`,
        method: 'PATCH',
        data
    })
};