import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Header from '../../component/Header/Header';
import Box from '@material-ui/core/Box';
import "react-perfect-scrollbar/dist/css/styles.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { userRoles} from '../../utils';
import { config } from '../../config/config';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        backgroundColor: '#fff',
        borderRadius: '0 10px 10px 10px',
        boxShadow: '0 3px 6px rgba(0,0,0,.16)',
        padding: theme.spacing(3.5),
        position: 'relative',
        width: '1150px',
        margin: '0 auto',
        marginBottom:30,
        '&:after': {
          content: '""',
          width: '2px',
          height: '48px',
          backgroundColor: '#6C59B4',
          position: 'absolute',
          left: '0',
          top: '0',
        },
        '& .MuiTypography-subtitle1':{
            fontWeight:600,
            marginBottom:'10px !important',
        },
        '& .MuiTypography-subtitle2':{
            fontWeight:'bold',
            marginBottom:'10px !important',
        },
        '& .MuiTypography-root':{
            marginBottom:20,
            '& a':{
                color:'#6C59B4',
                textDecoration:'none',
            }
        },
        '& ul':{
            margin:0,
            paddingLeft:0,
            '& li':{
                fontSize:14,
                listStyleType:'none',
                paddingBottom: 20,
                '& >span':{
                    paddingLeft: 28,
                    paddingRight: 12,
                }
            }
        },
        '& ul .MuiTypography-subtitle1 span':{
            paddingLeft: 0,
        }
        
        
    },
    bannerBg: {
        backgroundImage: 'url(/static/images/banner.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY:'-50px',
        minHeight:'508px',
    },
    pageBg: {
        backgroundImage: 'url(/static/images/page-bg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY:'-50px'
    },
    pageTitle:{
        fontSize:'18px',
        fontWeight:600,
        margin:'0 0 20px 0',
    },
    scrollBox: {
        maxHeight: '500px',
        position: 'relative',
        paddingRight: theme.spacing(1.5),        
      },
      table: {
          marginBottom:30,
          width:'75%',
          '& .MuiTableCell-head':{
              fontWeight:'bold',
          },
          '& .MuiTableCell-root':{
            border:'1px solid rgba(224, 224, 224, 1)',
            verticalAlign:'top',
            padding:'16px !important',
            width:'50%',
            
          }
      },
      btnWrapper:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:theme.spacing(3),
        padding:'0 40px',
    },
      inlineDiv:{
        display:'inline-flex',
        '& .MuiTypography-root':{
            fontWeight:600,
        }
    },
    button: {
        backgroundColor:'#6C59B4',
        padding:theme.spacing(1, 2.5),
        borderRadius:'20px',
        color:'#fff',
        textTransform:'inherit',
        border:0,
        outline:'none',
        cursor:'pointer',
        fontWeight:600,
        '&:hover':{
            backgroundColor:' #493E75',
        },
        marginBottom: "10px"
    },
    subtitle3:{
        fontWeight:'bold',
        marginBottom:'10px !important',
        fontSize:'1rem',
        margin:'0 auto',
        textAlign:'center',
    },
    textCenter:{
        textAlign:'center',
    },
    bold:{
        fontWeight: 'bold',
    },
    padTop:{
        paddingTop:15,
    },
    noPad:{
        padding :'0px !important',
    },
    padRight:{
        paddingRight: 12,
        paddingLeft:'0px !important'
    },
    underLine:{
        textDecorationLine: 'underline',
    }

    
}))

const Movement = () => {
    const classes = useStyles(); 
    const [state, setStates] = React.useState(false);
    const handleChangeCheckbox = (event) => {
        state ? setStates(false) :setStates(true) ;
    };
    
    return(        
        <Container fixed>
            <Box className={`${classes.bannerBg} ${classes.pageBg}`}>
                <Header />
                <Box className={classes.mainWrapper}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Typography variant="subtitle1">A Helicopter Pilot Movement</Typography>
                        <Button className={classes.button}
                            onClick={() => window.open(process.env.REACT_APP_GOOGLE_FORM_LINK_FOR_PROSPECT_CN)}
                            target="blank"
                        >
                            Become a Career Navigator
                        </Button>
                    </div>
                    <Typography>
                    Small moves, smartly made, can set big things in motion to improve people’s lives. Ident is starting a movement in the helicopter industry, and our vision is to develop a network of trusted advisors with the tools to help those working in the helicopter industry better pursue career well-being and assist employers to better improve their business performance.
                    </Typography>
                    <Typography>
                    The key is to make it easy for helicopter pilots to be the trusted "go-to" person to help other helicopter pilots improve their career well-being.
                    </Typography>
                    <Typography>
                    Why do people do this? Because they get rewarded for doing it. Helping other people is one of the most joyous things in life. Ident empowers people to make a difference in other people's lives, while at the same time, enriching their own.
                    </Typography>

                    <Typography variant="subtitle1">
                    Life Stages Have Changed
                    </Typography>
                    <Typography>
                    The internet has changed everything, but the helicopter industry has been slow to recognize that it represents the biggest opportunity to use the recruiting/placement transaction as the trigger to improve helicopter pilots’ overall well-being. For far too long, helicopter pilots have struggled with jobs where they are unhappy or where they are not developing the best skills or gaining the best experience to reach their lifetime goals.
                    </Typography>
                    <Typography>
                    At Ident, we take a different approach by making it easy for people like yourself to monetize your reputation as a referrer, mentor, or even an agent and earn an income doing it. For you, it is about unlimited potential and the opportunity to be the "go to" person to place pilots in the industry. Ident is a movement. Movements start with tiny steps. Take your first step, do it for your future. Do it for your family. Just do it because it feels good to help someone else get ahead!
                    </Typography>
                    <Typography>
                    You can use Ident on a part-time or full-time basis, it is up to you with no obligation whatsoever. Build your consultancy at your own pace and we will be there supporting you all the way! From the moment you join our network, you will have access to countless resources specifically designed to support you as you help other helicopter pilots improve their career well-being. As a Career Navigator you will be paid from 55% to 70% of the revenue you produce. Simply, we don’t make money unless you make money. Now, that's a fair handshake.
                    </Typography>

                    <Typography variant="subtitle1">
                    Helicopter Pilots Advise Helicopter Pilots, Not Others or Technology
                    </Typography>
                    <Typography>
                    What more noble cause could there be? Ident is bringing to market innovative technology that enables you to build your own consultancy. There are so many good people that need help getting through the cumbersome recruiting and placement processes and you can help them get placed accurately, quickly and affordably using technology that helps you quickly deliver appropriate and accurate advice. It is designed for you. It really feels good to help other people lead happier, more productive lives. It feels even better to be rewarded for it.
                    </Typography>
                    <Typography>
                    As the owner of Ident, my job is to make it easy for you to improve lives, increase employer business productivity,  and to make money in the process. The helicopter industry needs people like you to do this!
                    </Typography>

                    <Typography variant="subtitle1">
                    Here's What You Need To Know
                    </Typography>
                    <Typography>
                    First, the platform rewards your activity working recruiting orders entered by employers and placement orders entered by pilots. You can be the person that really makes a difference to help others.
                    </Typography>
                    <Typography>
                    Second, you can learn all aspects of building a strong reputation with access to the technology at any time, from anywhere. Maximize your time and efforts with innovative technology. You will have access to your own personal dashboard that makes it easy for you to know who has a recruiting or placement need and since you'll be the "go to" person they'll be interested in your help! It's as simple as that!
                    </Typography>

                    <Typography variant="subtitle1">
                    There Aren’t Any Excuses
                    </Typography>
                    <Typography>
                    You can change your financial future. If not now, when? You have a chance to be part of a movement and do something truly historic in our industry. Help turn this movement into a tidal wave!
                    </Typography>
                    <Typography>
                    Let's get helicopter pilots on the right track. After all, we’re in this together. Ident’s movement can revolutionize the way we help other helicopter pilots lead more productive and happier lives around the world.
                    </Typography>
                    <Typography>
                    Join the movement and learn how you can benefit by making a difference.
                    </Typography>
                    <Typography>
                    Thank you so much for your time and we look forward to seeing you online soon.
                    </Typography>
                </Box>
            </Box>
                   </Container>
    )
}

export default Movement
