import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const handleClose = () =>{
  handleClose()
}

const  ToasterNotification = ({open=false,message='',type='',handleClose}) =>{

    const [toastOpen , setToastOpen] = useState({open:false})
    
    useEffect(()=>{
        setToastOpen({open:open,message:message,type:type})
    },[])

    return( 
    <Snackbar 
        open={toastOpen.open} 
        autoHideDuration={6000} 
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        >
          <Alert onClose={handleClose} severity={type} onBlur={handleClose}>
            {message}
          </Alert>
        </Snackbar>
  )
}
 export default ToasterNotification;
