import axios from 'axios'
import { config } from '../../config/config';
import history from '../../customHistory';
import { logout } from '../../utils/authFunctions';
 
/**
 * Request Wrapper with default success/error actions
 */
const requestToken = (options) => {
    /**
     * Create an Axios Client with defaults
     */
    // if(options.method === 'POST' || options.method === 'PUT' || options.method === 'DELETE' || options.method === 'PATCH'){
    
    // }
    const token = localStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
    }
    if(token){
        headers = {
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    }
    const client = axios.create({
        baseURL: config.api_url,
        headers: {
            ...headers
        },
    });
    // if(!!localStorage.getItem('businessId')){
    // axios.defaults.header['x-peymynt-business-id'] = localStorage.getItem('businessId')
    // }
 
    const onSuccess = (response) => {
        return response.data;
    }
 
    const onError = async (error) => {
        console.error("API Error => ", error);
        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            if (error.response.status === 440) {
                //If refresh token expires
                logout()
            }
            // if(error.response.status === 404){
            //     history.push('/')
            // }
            return Promise.reject(!!error.response.data ? error.response.data : error.response || error.message);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            // history.push('/')
            
            return Promise.reject(error.request);
        } else {
            // Something else happened while setting up the request
            // triggered the error
            // history.push('/')
            return Promise.reject(error.message);
        }
 
    }
 
    return client(options)
    .then(onSuccess)
    .catch(onError);
}
 
export default requestToken;