import React,{Suspense, lazy} from "react";
import "./App.css";
import { Route, Switch, Redirect } from 'react-router-dom'; 
import AppSpinner from './component/AppSpinner';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ContractVerify from "./component/Employer/ContractVerify";
import AccountVerify from "./views/accountVerify";
import TermsOfUse from "./views/auth/TermsOfUse";
import EULAPolicy from "./views/auth/EULAPolicy";
import ContactUs from "./views/auth/Contact";
import MovementPage from './views/auth/MovementPage';
import AboutUs from './views/auth/AboutUs'

import VerifyIO from './views/auth/VerifyIO'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif'
    ].join(','),
  }
});

const useStyles = makeStyles({
  container:{
    display:'flex',
    flexWrap:'wrap',
    height:'100%'
  },
})

const Index = lazy(()=>import('./component/RecruitingOrder/Index'))

const Login = lazy(()=>import('./views/auth/Login'))
const Register = lazy(()=>import('./views/auth/Register'))
const ResetPassword = lazy(()=>import('./views/auth/ResetPassword'))
const UserAdministration = lazy(()=>import('./component/SuperAdmin/UserAdministration'));
const Pages = lazy(()=>import('./component/Pages/Pages'));
const RedirectPage = lazy(()=>import('./component/DataSwift/Redirect'))
const FallbackPage = lazy(()=>import('./component/DataSwift/Fallback'))
const worker_signup = lazy(()=>import('./component/Worker/Worker_signup'))
const PrivacyPolicy = lazy(()=>import('./views/auth/PrivacyPolicy'))
const IoOrderDetails =  lazy(()=>import('./component/Io/IoOrderDetails'));
const Faq =  lazy(()=>import('./views/auth/FAQ'));


export default function App(){
  const classes = useStyles();

  return (   
    <ThemeProvider theme={theme}>
    <div className={classes.container}>
       <Suspense fallback={<AppSpinner/>}>
         
       {/* <TosterContext.Provider value={{toastOpenFlag,setToastOpenFlag}}>
                <ToasterNotification open= {toastOpenFlag.open} message ={toastOpenFlag.message} type = {toastOpenFlag.type}></ToasterNotification>
             */}
          <Switch>              
              <Route path='/login' component={Login} />
              <Route path='/auth/verify-registration' component={Register} />
              <Route path='/accountverify' component={AccountVerify} />
              <Route path='/auth/verify-change-password-link' component={ResetPassword} />
              <Route path='/home' component={Index} />
              <Route path='/addOrder' component={Index} />
              {/* <Route path='/Admin/Dashboard' component={UserAdministration} />
              <Route path='/LoopAdmin/Dashboard' component={UserAdministration} />
              <Route path='/CarrierNavigator/Dashboard' component={UserAdministration} /> */}
              <Route path='/RedirectPage' component={RedirectPage} />
              <Route path='/FallBackPage' component={FallbackPage} />
              <Route path='/WorkerSignup' component={worker_signup} />
              <Route path='/Pages' component={Pages} />
              <Route path='/contractVerify' component={ContractVerify}/>
              <Route path='/auth/verify/IO' component={VerifyIO} />
              <Route path = "/Io/OrderDetails/:ioToken" component={IoOrderDetails} />
              <Route path='/PrivacyPolicy' component={PrivacyPolicy}/>
              <Route path='/TermsOfService' component={TermsOfUse}/>
              <Route path='/EULA' component={EULAPolicy}/>
              <Route path='/ContactUs' component={ContactUs}/>
              <Route path='/MovementPage' component={MovementPage}/>
              <Route path='/Faq' component={Faq}/>
              <Route path='/AboutUs' component={AboutUs}/>
              
              
              
              
              
              <Redirect from ='' to='/home' />

          </Switch> 
          {/* </TosterContext.Provider> */}

        </Suspense> 
    </div>
   </ThemeProvider>
  )
}

